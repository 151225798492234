<template>
  <div>
    <TheNavbar/>

    <div class="container mt-4">
      <!-- RESTAURANTS LIST -->
      <div class="row mb-3">
        <div class="col-md-6 align-self-center animate fadeIn">
          <h1 class="fw-500 typography-headline-lg">Restaurants locations</h1>
        </div>
        <div class="col-md-6 text-md-end animate fadeIn" style="--animation-index: 0.7s">
          <router-link :to="{name: 'CreateLocation'}" class="btn btn-primary ripple px-3">
            <i class="material-icons-outlined">&#xe145;</i>
            Add new restaurant
          </router-link>
        </div>
      </div>

      <div class="mt-5 pt-5" v-if="locations.length < 1">
        <EmptyState title="No restaurant locations"
                    subtitle="You have not created any restaurant locations yet."/>
      </div>
      <div v-else class="row mb-3">
        <div v-for="(location, index) in locations"
             :key="`loc-${index}`"
             class="col-md-6 col-xl-4 mb-3 animate fadeIn"
             :style="{'--animation-index': 'calc(0.5s + 0.' + index + 's)'}">
          <div class="card card-outline">
            <div class="card-body">
              <div class="subtitle-1 py-1 mb-0">
                <i class="material-icons-outlined text-secondary me-2">&#xea12;</i>
                <span class="text-truncate d-inline-block align-middle" style="max-width: 180px;"
                      :title="location.name">
                  {{ location.name }}
                </span>
              </div>
              <div class="d-flex justify-content-between">
                <div>
                  <div class="badge badge-default-outline badge-truncate mt-2 px-3 mb-0" :title="location.placeName">
                    {{ location.placeName }}
                  </div>
                </div>
                <button @click="locationDelete(location.id)"
                        class="btn btn-link ripple ripple-primary m-0" title="Delete restaurant location">
                  <i class="material-icons-outlined">&#xe872;</i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <TheFooter/>
  </div>
</template>
<script>
import TheNavbar from '@/components/TheNavbar'
import TheFooter from '@/components/TheFooter'
import EmptyState from '@/components/empty-states/EmptyState'
import {db, firebaseAuth} from '@/firebase'
import {collection, deleteDoc, doc, getDocs} from 'firebase/firestore'
import Swal from 'sweetalert2'
import {Toast} from '@/utils'

export default {
  components: {TheFooter, TheNavbar, EmptyState},
  data() {
    return {
      locations: []
    }
  },
  mounted() {
    this.fetchRestaurantLocations()
  },
  methods: {
    async fetchRestaurantLocations() {
      const locationsRef = collection(db, `users/${firebaseAuth.currentUser.uid}/restaurantLocations`)
      const locationsSnap = await getDocs(locationsRef)
      this.locations = locationsSnap.docs.map(loc => ({id: loc.id, ...loc.data()}))
    },
    async locationDelete(locationId) {
      const result = await Swal.fire({
        title: 'Deleting a restaurant location',
        text: 'This will permanently delete the restaurant location and its complete menu. Do you want to continue?',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete the location and its menu',
        cancelButtonText: 'Cancel',
      })

      if (result.isDismissed || result.isDenied || !result.isConfirmed) {
        return
      }

      const docRef = doc(
          db, 'users', firebaseAuth.currentUser.uid, 'restaurantLocations', locationId)

      try {
        await deleteDoc(docRef)

        this.locations = this.locations.filter(location => locationId !== location.id)

        await Toast.fire({
          icon: 'success',
          title: 'Restaurant location deleted successfully.'
        })
      } catch (err) {
        await Toast.fire({
          icon: 'error',
          title: err.message
        })
      }
    }
  }
}
</script>
